import { uniqueId } from 'lodash'
import { ref } from 'vue'

import type { Message } from '@/components/common/toast/Message'
import { ToastSymbol } from '@/composables/useToast'
import EventBus from '@/utils/classes/EventBus'

const DEFAULT_OFFSET = 20

export class ToastService {
  private offsetValue = ref(DEFAULT_OFFSET)

  private readonly eventBusInstance!: EventBus

  constructor(eventBus: EventBus) {
    this.eventBusInstance = eventBus
  }

  add(message: Message) {
    this.eventBusInstance.emit('add', { id: uniqueId(), ...message })
  }

  removeGroup(group: string) {
    this.eventBusInstance.emit('remove-group', group)
  }

  removeAllGroups() {
    this.eventBusInstance.emit('remove-all-groups')
  }

  get offset(): string {
    return `${this.offsetValue.value}px`
  }

  get eventBus() {
    return this.eventBusInstance
  }

  setOffset(value?: number): void {
    this.offsetValue.value = value !== undefined ? value : DEFAULT_OFFSET
  }

  resetOffset(): void {
    this.setOffset()
  }
}

export const toastService = new ToastService(new EventBus())

export default {
  install(app: any) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,no-param-reassign
    app.config.globalProperties.$toast = toastService
    app.provide(ToastSymbol, toastService)
  },
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $toast: ToastService
  }
}
