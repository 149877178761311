import type { RouteRecordRaw } from 'vue-router'

export const NEWS = 'news'
export const NEWS_DETAILS = 'news-details'

export default [
  {
    path: '/news',
    name: NEWS,
    component: () => import('./views/News.vue'),
    children: [
      {
        path: ':id',
        name: NEWS_DETAILS,
        component: () => import('./views/News.vue'),
      },
    ],
  },
] as RouteRecordRaw[]
