import { debounce } from '@zxcvbn-ts/core'
import type { RouteLocationNormalized } from 'vue-router'

import { useUserRoles } from '@/composables/useUserRoles'
import { useCounterStore } from '@/modules/counter/store'
import { useInfoStore } from '@/modules/info/store'
import { useNewsStore } from '@/modules/news/store'

export const counterMiddleware = async (to: RouteLocationNormalized) => {
  if (!to.meta.auth) {
    return true
  }

  const counterStore = useCounterStore()
  const { isPractitioner } = useUserRoles()
  if (isPractitioner.value) {
    return
  }
  const newsStore = useNewsStore()
  const infoStore = useInfoStore()
  const [news, infoArticles] = await Promise.all([
    newsStore.getNewsArticleList({ limit: 1 }),
    infoStore.getInfoArticleList({ limit: 1 }),
  ])
  newsStore.hasNews = !!news.results?.length
  infoStore.hasInfoArticles = !!infoArticles.results?.length
  const debouncedCounter = debounce(counterStore.getCounter, 1000) // number value in ms
  await debouncedCounter()
}
