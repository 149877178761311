/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdminProfile } from '../models/AdminProfile';
import type { PaginatedReadOnlyShortAdminSurveyResultList } from '../models/PaginatedReadOnlyShortAdminSurveyResultList';
import type { PaginatedReadOnlyShortSurveyTemplateList } from '../models/PaginatedReadOnlyShortSurveyTemplateList';
import type { PatchedSurveyTemplate } from '../models/PatchedSurveyTemplate';
import type { SurveyTemplate } from '../models/SurveyTemplate';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AdminService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns any No response body
     * @throws ApiError
     */
    public adminTinymceFileUploadCreate(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/admin/tinymce/file-upload/',
        });
    }

    /**
     * @returns any No response body
     * @throws ApiError
     */
    public adminTinymceFilebrowserRetrieve(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin/tinymce/filebrowser/',
        });
    }

    /**
     * @returns AdminProfile
     * @throws ApiError
     */
    public adminMeRetrieve(): CancelablePromise<AdminProfile> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/admin/me/',
        });
    }

    /**
     * @returns PaginatedReadOnlyShortSurveyTemplateList
     * @throws ApiError
     */
    public adminSurveyTemplatesList({
        limit,
        offset,
        ordering,
        search,
    }: {
        /**
         * Number of results to return per page.
         */
        limit?: number,
        /**
         * The initial index from which to return the results.
         */
        offset?: number,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A search term.
         */
        search?: string,
    }): CancelablePromise<PaginatedReadOnlyShortSurveyTemplateList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/admin/survey-templates/',
            query: {
                'limit': limit,
                'offset': offset,
                'ordering': ordering,
                'search': search,
            },
        });
    }

    /**
     * @returns SurveyTemplate
     * @throws ApiError
     */
    public adminSurveyTemplatesCreate({
        requestBody,
    }: {
        requestBody: SurveyTemplate,
    }): CancelablePromise<SurveyTemplate> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/admin/survey-templates/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns SurveyTemplate
     * @throws ApiError
     */
    public adminSurveyTemplatesRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this Survey Template.
         */
        id: number,
    }): CancelablePromise<SurveyTemplate> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/admin/survey-templates/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns SurveyTemplate
     * @throws ApiError
     */
    public adminSurveyTemplatesUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this Survey Template.
         */
        id: number,
        requestBody: SurveyTemplate,
    }): CancelablePromise<SurveyTemplate> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/admin/survey-templates/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns SurveyTemplate
     * @throws ApiError
     */
    public adminSurveyTemplatesPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this Survey Template.
         */
        id: number,
        requestBody?: PatchedSurveyTemplate,
    }): CancelablePromise<SurveyTemplate> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/admin/survey-templates/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public adminSurveyTemplatesDestroy({
        id,
    }: {
        /**
         * A unique integer value identifying this Survey Template.
         */
        id: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/admin/survey-templates/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns PaginatedReadOnlyShortAdminSurveyResultList
     * @throws ApiError
     */
    public adminSurveyTemplatesResultsList({
        templateId,
        clinic,
        limit,
        offset,
        ordering,
        requestedAtAfter,
        requestedAtBefore,
        search,
        status,
        statusUpdatedAtAfter,
        statusUpdatedAtBefore,
        ward,
    }: {
        templateId: number,
        /**
         * Clinic
         */
        clinic?: Array<number>,
        /**
         * Number of results to return per page.
         */
        limit?: number,
        /**
         * The initial index from which to return the results.
         */
        offset?: number,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * Date of the survey were requested.
         */
        requestedAtAfter?: string,
        /**
         * Date of the survey were requested.
         */
        requestedAtBefore?: string,
        /**
         * A search term.
         */
        search?: string,
        /**
         * Status
         *
         * * `in_progress` - In Progress
         * * `completed` - Completed
         * * `requested` - Requested
         * * `canceled` - Canceled
         */
        status?: Array<'canceled' | 'completed' | 'in_progress' | 'requested'>,
        /**
         * Date of the survey were completed, canceled or requested.
         */
        statusUpdatedAtAfter?: string,
        /**
         * Date of the survey were completed, canceled or requested.
         */
        statusUpdatedAtBefore?: string,
        /**
         * Ward
         */
        ward?: Array<number>,
    }): CancelablePromise<PaginatedReadOnlyShortAdminSurveyResultList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/admin/survey-templates/{template_id}/results/',
            path: {
                'template_id': templateId,
            },
            query: {
                'clinic': clinic,
                'limit': limit,
                'offset': offset,
                'ordering': ordering,
                'requested_at_after': requestedAtAfter,
                'requested_at_before': requestedAtBefore,
                'search': search,
                'status': status,
                'status_updated_at_after': statusUpdatedAtAfter,
                'status_updated_at_before': statusUpdatedAtBefore,
                'ward': ward,
            },
        });
    }

    /**
     * @returns any No response body
     * @throws ApiError
     */
    public adminSurveyTemplatesResultsRetrieve({
        id,
        templateId,
    }: {
        /**
         * A unique integer value identifying this Survey.
         */
        id: number,
        templateId: number,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/admin/survey-templates/{template_id}/results/{id}/',
            path: {
                'id': id,
                'template_id': templateId,
            },
        });
    }

    /**
     * @returns binary
     * @throws ApiError
     */
    public adminSurveyTemplatesResultsExportRetrieve({
        templateId,
        clinic,
        ordering,
        requestedAtAfter,
        requestedAtBefore,
        search,
        status,
        statusUpdatedAtAfter,
        statusUpdatedAtBefore,
        ward,
    }: {
        templateId: number,
        /**
         * Clinic
         */
        clinic?: Array<number>,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * Date of the survey were requested.
         */
        requestedAtAfter?: string,
        /**
         * Date of the survey were requested.
         */
        requestedAtBefore?: string,
        /**
         * A search term.
         */
        search?: string,
        /**
         * Status
         *
         * * `in_progress` - In Progress
         * * `completed` - Completed
         * * `requested` - Requested
         * * `canceled` - Canceled
         */
        status?: Array<'canceled' | 'completed' | 'in_progress' | 'requested'>,
        /**
         * Date of the survey were completed, canceled or requested.
         */
        statusUpdatedAtAfter?: string,
        /**
         * Date of the survey were completed, canceled or requested.
         */
        statusUpdatedAtBefore?: string,
        /**
         * Ward
         */
        ward?: Array<number>,
    }): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/admin/survey-templates/{template_id}/results/export/',
            path: {
                'template_id': templateId,
            },
            query: {
                'clinic': clinic,
                'ordering': ordering,
                'requested_at_after': requestedAtAfter,
                'requested_at_before': requestedAtBefore,
                'search': search,
                'status': status,
                'status_updated_at_after': statusUpdatedAtAfter,
                'status_updated_at_before': statusUpdatedAtBefore,
                'ward': ward,
            },
        });
    }

}
