import type { I18nOptions } from 'vue-i18n'
import { createI18n } from 'vue-i18n'

import de from '@/locales/de.yaml'
import en from '@/locales/en.yaml'

// fix for ts-issue "Type instantiation is excessively deep and possibly infinite." when using i18n.global.t
// https://github.com/intlify/vue-i18n-next/issues/1119

const messages: I18nOptions['messages'] = {
  en,
  de,
}

const i18n = createI18n({
  legacy: false,
  locale: localStorage.getItem('lang') || 'de',
  fallbackLocale: 'en',
  globalInjection: true,
  messages,
})

export default i18n
