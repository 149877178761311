/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MyStay } from '../models/MyStay';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DashboardService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns MyStay
     * @throws ApiError
     */
    public dashboardMyStayRetrieve(): CancelablePromise<MyStay> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/dashboard/my-stay/',
        });
    }

}
