/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Appointment } from '../models/Appointment';
import type { AppointmentMarkRead } from '../models/AppointmentMarkRead';
import type { PaginatedAppointmentList } from '../models/PaginatedAppointmentList';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AppointmentsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedAppointmentList
     * @throws ApiError
     */
    public appointmentsList({
        limit,
        offset,
        ordering,
        startGte,
        startLte,
        type,
    }: {
        /**
         * Number of results to return per page.
         */
        limit?: number,
        /**
         * The initial index from which to return the results.
         */
        offset?: number,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        startGte?: string,
        startLte?: string,
        /**
         * The style of appointment or patient that has been booked in the slot (not service type) icon
         *
         * * `ROUTINE` - Routine
         * * `Virtual` - Virtual
         */
        type?: 'ROUTINE' | 'Virtual' | null,
    }): CancelablePromise<PaginatedAppointmentList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/appointments/',
            query: {
                'limit': limit,
                'offset': offset,
                'ordering': ordering,
                'start__gte': startGte,
                'start__lte': startLte,
                'type': type,
            },
        });
    }

    /**
     * @returns Appointment
     * @throws ApiError
     */
    public appointmentsRetrieve({
        id,
    }: {
        id: number,
    }): CancelablePromise<Appointment> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/appointments/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns AppointmentMarkRead
     * @throws ApiError
     */
    public appointmentsMarkReadCreate({
        requestBody,
    }: {
        requestBody: AppointmentMarkRead,
    }): CancelablePromise<AppointmentMarkRead> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/appointments/mark-read/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
