import type { RouteRecordRaw } from 'vue-router'

export const PROFILE = 'my-profile'

export default [
  {
    path: '/my-profile',
    name: PROFILE,
    component: () => import('./views/MyProfile.vue'),
    meta: {
      auth: true,
    },
  },
] as RouteRecordRaw[]
