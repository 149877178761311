import type { RouteRecordRaw } from 'vue-router'

export const HISTORY = 'history'

export default [
  {
    path: '/history',
    name: HISTORY,
    component: () => import('./views/History.vue'),
    meta: {
      auth: true,
    },
  },
] as RouteRecordRaw[]
