/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ReadOnlyFHIRServer } from '../models/ReadOnlyFHIRServer';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ClinicsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns ReadOnlyFHIRServer
     * @throws ApiError
     */
    public clinicsList({
        ordering,
        search,
    }: {
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A search term.
         */
        search?: string,
    }): CancelablePromise<Array<ReadOnlyFHIRServer>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/clinics/',
            query: {
                'ordering': ordering,
                'search': search,
            },
        });
    }

}
