import { defineEnv } from 'static-build-env'
const env = defineEnv() as Record<string, unknown>

const envNumber = (value: string | undefined | null, defaultValue: number) => {
  const number = Number(value)
  return Number.isNaN(number) ? defaultValue : number
}

export const ENV = env.ENV || import.meta.env.ENV || 'local'
export const WEB_SENTRY_DSN = env.WEB_SENTRY_DSN || import.meta.env.WEB_SENTRY_DSN || ''
export const IOS_SENTRY_DSN = env.IOS_SENTRY_DSN || import.meta.env.VITE_IOS_SENTRY_DSN || ''
export const ANDROID_SENTRY_DSN =
  env.ANDROID_SENTRY_DSN || import.meta.env.VITE_ANDROID_SENTRY_DSN || ''
export const SENTRY_TRACE_SAMPLE_RATE = envNumber(
  env.SENTRY_TRACE_SAMPLE_RATE || import.meta.env.SENTRY_TRACE_SAMPLE_RATE,
  0.1
)
