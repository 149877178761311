/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { AxiosHttpRequest } from './core/AxiosHttpRequest';

import { AdminService } from './services/AdminService';
import { AppointmentsService } from './services/AppointmentsService';
import { BsclService } from './services/BsclService';
import { ClinicsService } from './services/ClinicsService';
import { CounterService } from './services/CounterService';
import { DashboardService } from './services/DashboardService';
import { EpisodesOfCareService } from './services/EpisodesOfCareService';
import { FcmtokenService } from './services/FcmtokenService';
import { InfoService } from './services/InfoService';
import { InvitationService } from './services/InvitationService';
import { LogHistoryService } from './services/LogHistoryService';
import { LoginService } from './services/LoginService';
import { MeService } from './services/MeService';
import { NewsService } from './services/NewsService';
import { PatientsService } from './services/PatientsService';
import { ResetPasswordService } from './services/ResetPasswordService';
import { SetPasswordService } from './services/SetPasswordService';
import { SurveysService } from './services/SurveysService';
import { TestService } from './services/TestService';
import { WebhookService } from './services/WebhookService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class ApiClient {

    public readonly admin: AdminService;
    public readonly appointments: AppointmentsService;
    public readonly bscl: BsclService;
    public readonly clinics: ClinicsService;
    public readonly counter: CounterService;
    public readonly dashboard: DashboardService;
    public readonly episodesOfCare: EpisodesOfCareService;
    public readonly fcmtoken: FcmtokenService;
    public readonly info: InfoService;
    public readonly invitation: InvitationService;
    public readonly logHistory: LogHistoryService;
    public readonly login: LoginService;
    public readonly me: MeService;
    public readonly news: NewsService;
    public readonly patients: PatientsService;
    public readonly resetPassword: ResetPasswordService;
    public readonly setPassword: SetPasswordService;
    public readonly surveys: SurveysService;
    public readonly test: TestService;
    public readonly webhook: WebhookService;

    public readonly request: BaseHttpRequest;

    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = AxiosHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? '',
            VERSION: config?.VERSION ?? '1.0.0',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });

        this.admin = new AdminService(this.request);
        this.appointments = new AppointmentsService(this.request);
        this.bscl = new BsclService(this.request);
        this.clinics = new ClinicsService(this.request);
        this.counter = new CounterService(this.request);
        this.dashboard = new DashboardService(this.request);
        this.episodesOfCare = new EpisodesOfCareService(this.request);
        this.fcmtoken = new FcmtokenService(this.request);
        this.info = new InfoService(this.request);
        this.invitation = new InvitationService(this.request);
        this.logHistory = new LogHistoryService(this.request);
        this.login = new LoginService(this.request);
        this.me = new MeService(this.request);
        this.news = new NewsService(this.request);
        this.patients = new PatientsService(this.request);
        this.resetPassword = new ResetPasswordService(this.request);
        this.setPassword = new SetPasswordService(this.request);
        this.surveys = new SurveysService(this.request);
        this.test = new TestService(this.request);
        this.webhook = new WebhookService(this.request);
    }
}

