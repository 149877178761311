<template>
  <div
    :class="{ inline, [severity]: true, [layout]: true }"
    :data-qa="severity"
    class="notification"
  >
    <SvgIcon :size="size" :name="icon || LevelToIcon[severity]" class="notification-icon" />
    <div class="message-text">
      <p v-if="summary" class="summary">{{ summary }}</p>
      <div class="detail">
        <slot>{{ detail }}</slot>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, type PropType } from 'vue'

import { LevelToIcon } from '@/components/common/icons'
import SvgIcon from '@/components/common/SvgIcon.vue'

const props = defineProps({
  severity: {
    type: String as PropType<'info' | 'success' | 'warn' | 'error' | 'neutral'>,
    default: 'info',
  },
  summary: { type: String, default: '' },
  detail: { type: String, default: '' },
  icon: { type: String, default: '' },
  inline: { type: Boolean, default: false },
  layout: { type: String as PropType<'vertical' | 'horizontal'>, default: 'horizontal' },
})

const size = computed(() => (props.inline ? 16 : 20))
</script>

<style scoped lang="postcss">
.notification {
  @apply flex;

  &:not(.inline) {
    @apply p-3 rounded-md;
  }

  .notification-icon {
    @apply mr-3 flex-shrink-0;
  }

  .message-text {
    @apply max-h-20 overflow-auto;
  }

  .summary {
    @apply font-bold mb-1;
  }

  &.info {
    .notification-icon {
      @apply text-accent-500;
    }
  }

  &.success {
    .notification-icon {
      @apply text-key-500;
    }
  }

  &.warn {
    .notification-icon {
      @apply text-warning-500;
    }
  }

  &.error {
    .notification-icon {
      @apply text-danger-500;
    }
  }

  &.neutral {
    .notification-icon {
      @apply text-white;
    }
  }

  &.inline {
    .notification-icon {
      @apply mt-[2px] mr-[6px];
    }
  }

  &.vertical {
    @apply flex-col items-center gap-2;

    .notification-icon {
      @apply m-0;
    }
  }
}
</style>
