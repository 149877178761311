/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelSurvey } from '../models/CancelSurvey';
import type { PaginatedPatientListReadOnlyList } from '../models/PaginatedPatientListReadOnlyList';
import type { PaginatedPractitionerBSCLHistoryList } from '../models/PaginatedPractitionerBSCLHistoryList';
import type { PaginatedPractitionerSurveyHistoryList } from '../models/PaginatedPractitionerSurveyHistoryList';
import type { PaginatedReadOnlyAnnotatedSurveyTemplateList } from '../models/PaginatedReadOnlyAnnotatedSurveyTemplateList';
import type { PaginatedReadOnlyEpisodeOfCareList } from '../models/PaginatedReadOnlyEpisodeOfCareList';
import type { PatchedCancelSurvey } from '../models/PatchedCancelSurvey';
import type { PatchedUpdateOnlyBSCLSurvey } from '../models/PatchedUpdateOnlyBSCLSurvey';
import type { PatchedUpdateOnlySurvey } from '../models/PatchedUpdateOnlySurvey';
import type { PractitionerRequestSurvey } from '../models/PractitionerRequestSurvey';
import type { ReadOnlyAvailableBSCLSurveyTemplate } from '../models/ReadOnlyAvailableBSCLSurveyTemplate';
import type { ReadOnlyBSCLSurvey } from '../models/ReadOnlyBSCLSurvey';
import type { ReadOnlySurvey } from '../models/ReadOnlySurvey';
import type { UpdateOnlyBSCLSurvey } from '../models/UpdateOnlyBSCLSurvey';
import type { UpdateOnlySurvey } from '../models/UpdateOnlySurvey';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PatientsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedPatientListReadOnlyList
     * @throws ApiError
     */
    public patientsList({
        limit,
        offset,
        ordering,
        search,
    }: {
        /**
         * Number of results to return per page.
         */
        limit?: number,
        /**
         * The initial index from which to return the results.
         */
        offset?: number,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A search term.
         */
        search?: string,
    }): CancelablePromise<PaginatedPatientListReadOnlyList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/patients/',
            query: {
                'limit': limit,
                'offset': offset,
                'ordering': ordering,
                'search': search,
            },
        });
    }

    /**
     * @returns PaginatedReadOnlyEpisodeOfCareList
     * @throws ApiError
     */
    public patientsEpisodesOfCareList({
        patientId,
        limit,
        offset,
        ordering,
        search,
    }: {
        patientId: number,
        /**
         * Number of results to return per page.
         */
        limit?: number,
        /**
         * The initial index from which to return the results.
         */
        offset?: number,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A search term.
         */
        search?: string,
    }): CancelablePromise<PaginatedReadOnlyEpisodeOfCareList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/patients/{patient_id}/episodes-of-care/',
            path: {
                'patient_id': patientId,
            },
            query: {
                'limit': limit,
                'offset': offset,
                'ordering': ordering,
                'search': search,
            },
        });
    }

    /**
     * @returns PaginatedReadOnlyAnnotatedSurveyTemplateList
     * @throws ApiError
     */
    public patientsEpisodesOfCareSurveyTemplatesList({
        eocId,
        patientId,
        limit,
        offset,
    }: {
        eocId: number,
        patientId: number,
        /**
         * Number of results to return per page.
         */
        limit?: number,
        /**
         * The initial index from which to return the results.
         */
        offset?: number,
    }): CancelablePromise<PaginatedReadOnlyAnnotatedSurveyTemplateList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/patients/{patient_id}/episodes-of-care/{eoc_id}/survey-templates/',
            path: {
                'eoc_id': eocId,
                'patient_id': patientId,
            },
            query: {
                'limit': limit,
                'offset': offset,
            },
        });
    }

    /**
     * @returns ReadOnlySurvey
     * @throws ApiError
     */
    public patientsEpisodesOfCareSurveyTemplatesActiveSurveyRetrieve({
        eocId,
        patientId,
        templateId,
    }: {
        eocId: number,
        patientId: number,
        templateId: number,
    }): CancelablePromise<ReadOnlySurvey> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/patients/{patient_id}/episodes-of-care/{eoc_id}/survey-templates/{template_id}/active-survey/',
            path: {
                'eoc_id': eocId,
                'patient_id': patientId,
                'template_id': templateId,
            },
        });
    }

    /**
     * @returns CancelSurvey
     * @throws ApiError
     */
    public patientsEpisodesOfCareSurveyTemplatesCancelUpdate({
        eocId,
        patientId,
        templateId,
        requestBody,
    }: {
        eocId: number,
        patientId: number,
        templateId: number,
        requestBody?: CancelSurvey,
    }): CancelablePromise<CancelSurvey> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/patients/{patient_id}/episodes-of-care/{eoc_id}/survey-templates/{template_id}/cancel/',
            path: {
                'eoc_id': eocId,
                'patient_id': patientId,
                'template_id': templateId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CancelSurvey
     * @throws ApiError
     */
    public patientsEpisodesOfCareSurveyTemplatesCancelPartialUpdate({
        eocId,
        patientId,
        templateId,
        requestBody,
    }: {
        eocId: number,
        patientId: number,
        templateId: number,
        requestBody?: PatchedCancelSurvey,
    }): CancelablePromise<CancelSurvey> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/patients/{patient_id}/episodes-of-care/{eoc_id}/survey-templates/{template_id}/cancel/',
            path: {
                'eoc_id': eocId,
                'patient_id': patientId,
                'template_id': templateId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns PaginatedPractitionerSurveyHistoryList
     * @throws ApiError
     */
    public patientsEpisodesOfCareSurveyTemplatesHistoryList({
        eocId,
        patientId,
        templateId,
        limit,
        offset,
    }: {
        eocId: number,
        patientId: number,
        templateId: number,
        /**
         * Number of results to return per page.
         */
        limit?: number,
        /**
         * The initial index from which to return the results.
         */
        offset?: number,
    }): CancelablePromise<PaginatedPractitionerSurveyHistoryList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/patients/{patient_id}/episodes-of-care/{eoc_id}/survey-templates/{template_id}/history/',
            path: {
                'eoc_id': eocId,
                'patient_id': patientId,
                'template_id': templateId,
            },
            query: {
                'limit': limit,
                'offset': offset,
            },
        });
    }

    /**
     * @returns ReadOnlySurvey
     * @throws ApiError
     */
    public patientsEpisodesOfCareSurveyTemplatesHistoryRetrieve({
        eocId,
        patientId,
        surveyId,
        templateId,
    }: {
        eocId: number,
        patientId: number,
        surveyId: number,
        templateId: number,
    }): CancelablePromise<ReadOnlySurvey> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/patients/{patient_id}/episodes-of-care/{eoc_id}/survey-templates/{template_id}/history/{survey_id}/',
            path: {
                'eoc_id': eocId,
                'patient_id': patientId,
                'survey_id': surveyId,
                'template_id': templateId,
            },
        });
    }

    /**
     * @returns PractitionerRequestSurvey
     * @throws ApiError
     */
    public patientsEpisodesOfCareSurveyTemplatesRequestCreate({
        eocId,
        patientId,
        templateId,
        requestBody,
    }: {
        eocId: number,
        patientId: number,
        templateId: number,
        requestBody?: PractitionerRequestSurvey,
    }): CancelablePromise<PractitionerRequestSurvey> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/patients/{patient_id}/episodes-of-care/{eoc_id}/survey-templates/{template_id}/request/',
            path: {
                'eoc_id': eocId,
                'patient_id': patientId,
                'template_id': templateId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns UpdateOnlySurvey
     * @throws ApiError
     */
    public patientsEpisodesOfCareSurveyTemplatesRespondUpdate({
        eocId,
        patientId,
        templateId,
        requestBody,
    }: {
        eocId: number,
        patientId: number,
        templateId: number,
        requestBody?: UpdateOnlySurvey,
    }): CancelablePromise<UpdateOnlySurvey> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/patients/{patient_id}/episodes-of-care/{eoc_id}/survey-templates/{template_id}/respond/',
            path: {
                'eoc_id': eocId,
                'patient_id': patientId,
                'template_id': templateId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns UpdateOnlySurvey
     * @throws ApiError
     */
    public patientsEpisodesOfCareSurveyTemplatesRespondPartialUpdate({
        eocId,
        patientId,
        templateId,
        requestBody,
    }: {
        eocId: number,
        patientId: number,
        templateId: number,
        requestBody?: PatchedUpdateOnlySurvey,
    }): CancelablePromise<UpdateOnlySurvey> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/patients/{patient_id}/episodes-of-care/{eoc_id}/survey-templates/{template_id}/respond/',
            path: {
                'eoc_id': eocId,
                'patient_id': patientId,
                'template_id': templateId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ReadOnlyAvailableBSCLSurveyTemplate
     * @throws ApiError
     */
    public patientsEpisodesOfCareSurveyTemplatesBsclRetrieve({
        eocId,
        patientId,
    }: {
        eocId: number,
        patientId: number,
    }): CancelablePromise<ReadOnlyAvailableBSCLSurveyTemplate> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/patients/{patient_id}/episodes-of-care/{eoc_id}/survey-templates/bscl/',
            path: {
                'eoc_id': eocId,
                'patient_id': patientId,
            },
        });
    }

    /**
     * @returns ReadOnlyBSCLSurvey
     * @throws ApiError
     */
    public patientsEpisodesOfCareSurveyTemplatesBsclActiveSurveyRetrieve({
        eocId,
        patientId,
    }: {
        eocId: number,
        patientId: number,
    }): CancelablePromise<ReadOnlyBSCLSurvey> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/patients/{patient_id}/episodes-of-care/{eoc_id}/survey-templates/bscl/active-survey/',
            path: {
                'eoc_id': eocId,
                'patient_id': patientId,
            },
        });
    }

    /**
     * @returns PaginatedPractitionerBSCLHistoryList
     * @throws ApiError
     */
    public patientsEpisodesOfCareSurveyTemplatesBsclHistoryList({
        eocId,
        patientId,
        limit,
        offset,
    }: {
        eocId: number,
        patientId: number,
        /**
         * Number of results to return per page.
         */
        limit?: number,
        /**
         * The initial index from which to return the results.
         */
        offset?: number,
    }): CancelablePromise<PaginatedPractitionerBSCLHistoryList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/patients/{patient_id}/episodes-of-care/{eoc_id}/survey-templates/bscl/history/',
            path: {
                'eoc_id': eocId,
                'patient_id': patientId,
            },
            query: {
                'limit': limit,
                'offset': offset,
            },
        });
    }

    /**
     * @returns ReadOnlyBSCLSurvey
     * @throws ApiError
     */
    public patientsEpisodesOfCareSurveyTemplatesBsclHistoryRetrieve({
        eocId,
        patientId,
        surveyId,
    }: {
        eocId: number,
        patientId: number,
        surveyId: number,
    }): CancelablePromise<ReadOnlyBSCLSurvey> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/patients/{patient_id}/episodes-of-care/{eoc_id}/survey-templates/bscl/history/{survey_id}/',
            path: {
                'eoc_id': eocId,
                'patient_id': patientId,
                'survey_id': surveyId,
            },
        });
    }

    /**
     * @returns UpdateOnlyBSCLSurvey
     * @throws ApiError
     */
    public patientsEpisodesOfCareSurveyTemplatesBsclRespondUpdate({
        eocId,
        patientId,
        requestBody,
    }: {
        eocId: number,
        patientId: number,
        requestBody?: UpdateOnlyBSCLSurvey,
    }): CancelablePromise<UpdateOnlyBSCLSurvey> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/patients/{patient_id}/episodes-of-care/{eoc_id}/survey-templates/bscl/respond/',
            path: {
                'eoc_id': eocId,
                'patient_id': patientId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns UpdateOnlyBSCLSurvey
     * @throws ApiError
     */
    public patientsEpisodesOfCareSurveyTemplatesBsclRespondPartialUpdate({
        eocId,
        patientId,
        requestBody,
    }: {
        eocId: number,
        patientId: number,
        requestBody?: PatchedUpdateOnlyBSCLSurvey,
    }): CancelablePromise<UpdateOnlyBSCLSurvey> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/patients/{patient_id}/episodes-of-care/{eoc_id}/survey-templates/bscl/respond/',
            path: {
                'eoc_id': eocId,
                'patient_id': patientId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
