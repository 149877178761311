<template>
  <Teleport to="body">
    <div>
      <transition name="overlay">
        <Overlay v-if="open && showOverlay" @click.stop="$emit('overlay-click')" />
      </transition>
      <div class="sidebar left" :class="{ closed, animate }" @click.stop="$emit('sidebar-click')">
        <div class="glow"></div>
        <div class="header">
          <slot name="header"></slot>
        </div>
        <div class="content">
          <slot />
        </div>
        <div class="footer">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import Overlay from '@/components/common/overlay/Overlay.vue'

const props = withDefaults(
  defineProps<{
    open: boolean
    animate?: boolean
    showOverlay?: boolean
  }>(),
  {
    animate: true,
    showOverlay: true,
  }
)

defineEmits<{
  (e: 'overlay-click'): void
  (e: 'sidebar-click'): void
}>()

const closed = computed(() => {
  return !props.open
})
</script>

<style scoped lang="postcss">
.sidebar {
  @apply box-content flex flex-col absolute inset-y-0
    bg-base-gradient z-sidebar
     w-[calc(100%-40px)] sm:w-[350px];

  &.left {
    @apply right-0 left-0
      transition-[background-color,left]
      duration-sidebar-opening;

    &.closed {
      @apply left-[calc(-100%-8rem)]
        transition-[background-color,left]
        duration-sidebar-closing;
    }
  }

  .header {
    @apply px-5 pt-5;
  }

  .content {
    @apply px-5 flex-grow overflow-y-auto flex flex-col;
  }
  .footer {
    @apply px-5 pb-8;
  }

  &:not(.animate).left {
    @apply transition-none;

    .closed {
      @apply transition-none;
    }
  }
}

.overlay-enter-active,
.overlay-leave-active {
  @apply transition-opacity duration-sidebar-opening;
}

.overlay-enter-from,
.overlay-leave-to {
  @apply opacity-0;
}

.glow {
  background-image: url('@/assets/background-glow-menu.png');
  background-size: cover;
  @apply absolute z-[-2] top-[60px] right-0 left-[-350px] w-[calc(100%+350px)] h-[calc(100%+60px)] pointer-events-none;
}
</style>
