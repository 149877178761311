import type { RouteRecordRaw } from 'vue-router'

export const SETTINGS = 'settings'

export default [
  {
    path: '/settings',
    name: SETTINGS,
    component: () => import('./views/Settings.vue'),
    meta: {
      auth: true,
    },
  },
] as RouteRecordRaw[]
