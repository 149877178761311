import type { RouteRecordRaw } from 'vue-router'

import AuthLayout from './layouts/AuthLayout.vue'

export const LOGIN = 'auth.login'
export const LOGOUT = 'auth.logout'
export const INVITATION = 'auth.invitation'
export const SET_NEW_PASSWORD = 'auth.set-new-password'
export const PASSWORD_RESET = 'auth.password-reset'

export default [
  {
    path: '/login',
    name: LOGIN,
    component: () => import('./views/LoginView.vue'),
    meta: {
      auth: false,
      layout: AuthLayout,
    },
  },
  {
    path: '/invitation/:token/',
    name: INVITATION,
    component: () => import('./views/SetNewPassword.vue'),
    meta: {
      auth: false,
      layout: AuthLayout,
    },
  },
  {
    path: '/set-new-password/:token/',
    name: SET_NEW_PASSWORD,
    component: () => import('./views/SetNewPassword.vue'),
    meta: {
      auth: false,
      layout: AuthLayout,
    },
  },
  {
    path: '/password-recovery',
    name: PASSWORD_RESET,
    component: () => import('./views/PasswordReset.vue'),
    meta: {
      auth: false,
      layout: AuthLayout,
    },
  },
] as RouteRecordRaw[]
