<template>
  <Toast></Toast>
  <component :is="layout">
    <RouterView />
  </component>
</template>

<script setup lang="ts">
import { App, type URLOpenListenerEvent } from '@capacitor/app'
import { Capacitor } from '@capacitor/core'
import { StatusBar, Style } from '@capacitor/status-bar'
import axios, { AxiosError, type AxiosResponse } from 'axios'
import { computed, defineComponent, onBeforeMount, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'

import Toast from '@/components/common/toast/Toast.vue'
import DefaultLayout from '@/layouts/DefaultLayout.vue'

import { useToast } from './composables/useToast'
import { useUserRoles } from './composables/useUserRoles'
import { PLATFORMS, TOAST_TIME } from './constants/constants'
import type { ApiError } from './generated/api'
import { LOGIN } from './modules/auth/routes'
import { useAuthStore } from './modules/auth/store'
import { PRACTITIONER_SURVEYS } from './modules/survey/routes'
import { useSurveyStore } from './modules/survey/store'

const route = useRoute()
const router = useRouter()
const toast = useToast()
const authStore = useAuthStore()
const surveyStore = useSurveyStore()
const layout = computed(
  (): ReturnType<typeof defineComponent> => route.meta.layout || DefaultLayout
)
const { isPractitioner } = useUserRoles()
const { t } = useI18n()

onBeforeMount(async () => {
  axios.interceptors.response.use(
    (response: AxiosResponse) => response,
    async (e: AxiosError<{ detail: string }>) => {
      if (e?.response?.status === 401) {
        if (
          !e?.request?.responseURL?.includes('refresh') &&
          !e?.response?.data?.detail.includes('token')
        ) {
          toast.add({
            severity: 'error',
            detail: (e.response?.data as ApiError['body']).detail,
            ttl: TOAST_TIME,
          })
        }
        await authStore.logout()
        router.push({ name: LOGIN, query: { redirect_to: route.query.redirect_to } })
        return e?.response || {}
      } else if ((e as any)?.body?.non_field_errors?.length) {
        ;(e as any)?.body?.non_field_errors?.forEach((error: string) => {
          toast.add({
            severity: 'error',
            detail: error,
            ttl: TOAST_TIME,
          })
        })
      } else if ((e?.response?.data as ApiError['body'])?.detail) {
        toast.add({
          severity: 'error',
          detail: (e.response?.data as ApiError['body']).detail,
          ttl: TOAST_TIME,
        })
      } else if ((e?.response?.data as any)?.code) {
        toast.add({
          severity: 'error',
          detail: t('system.wrong-account-error'),
          ttl: TOAST_TIME,
        })
      }
      return e?.response || {}
    }
  )
  await App.removeAllListeners()

  App.addListener('appUrlOpen', function (event: URLOpenListenerEvent) {
    // Example url: https://beerswift.ch/tabs/tabs2
    // slug = /tabs/tabs2
    const slug = event.url
      .split('.ch')
      .pop()
      ?.replace(/\/\//g, '/') // workaround for double slash
      .replace(/\/\?/g, '?') // workaround for slash before query params to handle proper title
      .replace(/\/$/, '') // workaround for trailing slash
    if (slug) {
      const [path, queryParams] = slug.split('?')
      const query = queryParams
        ?.split('&')
        .map((param) => param.split('='))
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})
      router.push({
        path,
        query,
      })
    }
  })

  App.addListener('appStateChange', async ({ isActive }) => {
    const data = surveyStore.practitionerData
    if (isActive) {
      const now = Date.now()
      const last = localStorage.getItem('lastActive')
      localStorage.removeItem('lastActive')
      if (!authStore.user || !last) return
      if (now - +last > authStore.refreshTokenLifetime) {
        await authStore.logout()
        router.push({ name: LOGIN, query: { redirect_to: route.fullPath } })
      } else if (isPractitioner.value && data.patientId) {
        await authStore.refreshLongLivedToken()
        router.push({ name: PRACTITIONER_SURVEYS })
      } else {
        await authStore.refreshToken()
      }
      return
    }
    if (!authStore.user) return
    authStore.stopRefreshTimeout()
    if (isPractitioner.value && data.patientId) {
      await authStore.refreshLongLivedToken()
    } else {
      await authStore.refreshToken()
    }
    localStorage.setItem('lastActive', Date.now().toString())
  })
})

onMounted(async () => {
  if (!Capacitor.isNativePlatform()) {
    return
  }
  await StatusBar.setStyle({ style: Style.Light })
  if (Capacitor.getPlatform() === PLATFORMS.ANDROID) {
    await StatusBar.setBackgroundColor({ color: '#ffffff' })
  }
})
</script>
