import { defineStore } from 'pinia'
import { ref } from 'vue'

import { useApiClient } from '@/api'
import type {
  CancelablePromise,
  NewsArticlesDetail,
  PaginatedNewsArticlesListList,
} from '@/generated/api'

export const useNewsStore = defineStore('news', () => {
  const apiClient = useApiClient()
  const hasNews = ref(false)

  function getNewsArticleList(
    params: {
      limit?: number
      offset?: number
      ordering?: string
      search?: string
    } = {}
  ): CancelablePromise<PaginatedNewsArticlesListList> {
    return apiClient.news.newsArticlesList({ limit: 20, ...params })
  }

  function getNewsArticle(id: number): CancelablePromise<NewsArticlesDetail> {
    return apiClient.news.newsArticlesRetrieve({ id })
  }

  async function loadNextNewsArticles(url: string): Promise<PaginatedNewsArticlesListList | null> {
    const urlObj = new URL(url)
    const newURLWithoutHost = urlObj.href.replace(urlObj.origin, '')
    return apiClient.request.request({ method: 'GET', url: newURLWithoutHost })
  }

  return { getNewsArticle, getNewsArticleList, loadNextNewsArticles, hasNews }
})
