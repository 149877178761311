/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedReadOnlyEpisodeOfCareList } from '../models/PaginatedReadOnlyEpisodeOfCareList';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class EpisodesOfCareService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedReadOnlyEpisodeOfCareList
     * @throws ApiError
     */
    public episodesOfCareList({
        limit,
        offset,
        ordering,
        search,
    }: {
        /**
         * Number of results to return per page.
         */
        limit?: number,
        /**
         * The initial index from which to return the results.
         */
        offset?: number,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A search term.
         */
        search?: string,
    }): CancelablePromise<PaginatedReadOnlyEpisodeOfCareList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/episodes-of-care/',
            query: {
                'limit': limit,
                'offset': offset,
                'ordering': ordering,
                'search': search,
            },
        });
    }

}
