import './assets/css/main.css'
import 'smart-app-banner/dist/smart-app-banner.css'
import 'nouislider/distribute/nouislider.css'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'

import { Capacitor } from '@capacitor/core'
import FloatingVue from 'floating-vue'
import { createPinia } from 'pinia'
import SmartBanner from 'smart-app-banner'
import { surveyPlugin } from 'survey-vue3-ui'
import { createApp } from 'vue'
import VueVirtualScroller from 'vue-virtual-scroller'
import VueZoomer from 'vue-zoomer'
import VuePdf from 'vue3-pdfjs'

import ToastPlugin from '@/components/common/toast/ToastService'
import i18n from '@/i18n'

import App from './App.vue'
import router from './router'
import { initSentry } from './sentry'

const app = createApp(App)
app.mixin({
  created() {
    if (Capacitor.isNativePlatform()) {
      return
    }
    new SmartBanner({
      title: 'myClienia',
      author: 'Clienia AG',
    })
  },
})
initSentry(app, router)

app
  .use(i18n)
  .use(createPinia())
  .use(router)
  .use(FloatingVue)
  .use(ToastPlugin)
  .use(VueZoomer)
  .use(VueVirtualScroller)
  .use(VuePdf)
  .use(surveyPlugin)

router.isReady().then(() => {
  app.mount('#app')
})

const script = document.createElement('script')
script.setAttribute(
  'src',
  (import.meta.env.VITE_BASE_URL || location.origin) + '/admin/v2/customQuestions.js'
)
script.setAttribute('type', 'application/ecmascript')

document.body.appendChild(script)
