/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { INESLogin } from '../models/INESLogin';
import type { INESWebhook } from '../models/INESWebhook';
import type { INESWebhookResponse } from '../models/INESWebhookResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class WebhookService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns INESWebhookResponse
     * @throws ApiError
     */
    public webhookCreate({
        requestBody,
    }: {
        requestBody: INESWebhook,
    }): CancelablePromise<INESWebhookResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/webhook/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Takes a set of user credentials and returns an access and refresh JSON web
     * token pair to prove the authentication of those credentials.
     * @returns INESLogin
     * @throws ApiError
     */
    public webhookAuthCreate({
        requestBody,
    }: {
        requestBody: INESLogin,
    }): CancelablePromise<INESLogin> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/webhook/auth/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
