import { defineStore } from 'pinia'
import { ref } from 'vue'

import { useApiClient } from '@/api'
import type { Counter } from '@/generated/api'

import { useAuthStore } from '../auth/store'

export const useCounterStore = defineStore('counter', () => {
  const apiClient = useApiClient()
  const counter = ref<Counter | null>(null)
  const auth = useAuthStore()

  async function getCounter() {
    if (!auth.user) {
      return
    }
    counter.value = await apiClient.counter.counterRetrieve()
  }
  return {
    counter,
    getCounter,
  }
})
