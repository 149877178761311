import { App as CapacitorApp } from '@capacitor/app'
import { Capacitor } from '@capacitor/core'
import { BrowserTracing } from '@sentry/browser'
import * as Sentry from '@sentry/capacitor'
import { init, vueRouterInstrumentation } from '@sentry/vue'
import type { App } from 'vue'
import type { Router } from 'vue-router'

import { PLATFORMS } from '@/constants/constants'

import {
  ANDROID_SENTRY_DSN,
  ENV,
  IOS_SENTRY_DSN,
  SENTRY_TRACE_SAMPLE_RATE,
  WEB_SENTRY_DSN,
} from './settings'

export async function initSentry(app: App, router: Router) {
  const dsnMap = {
    [PLATFORMS.IOS]: IOS_SENTRY_DSN,
    [PLATFORMS.ANDROID]: ANDROID_SENTRY_DSN,
    [PLATFORMS.WEB]: WEB_SENTRY_DSN,
  }

  const platform = Capacitor.getPlatform()
  const isWeb = platform === PLATFORMS.WEB

  const { build, version } = isWeb
    ? { build: 'web', version: '1.0.0' }
    : await CapacitorApp.getInfo()

  if (dsnMap[platform]) {
    Sentry.init(
      {
        app,
        dsn: dsnMap[platform],
        dist: build,
        release: `clienia-${platform}@${version}`,
        environment: ENV,
        integrations: [
          new BrowserTracing({
            routingInstrumentation: vueRouterInstrumentation(router),
            tracePropagationTargets: ['localhost', /^\//],
          }),
        ],
        tracesSampleRate: SENTRY_TRACE_SAMPLE_RATE,
      },
      init
    )
  }
}
