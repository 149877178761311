import type { RouteLocationNormalized } from 'vue-router'

import { USER_ROLES } from '@/constants/constants'
import { ApiError } from '@/generated/api'
import { APPOINTMENTS } from '@/modules/appointments/routes'

import { HOME } from '../home/routes'
import { PRACTITIONER_SURVEYS, SURVEYS } from '../survey/routes'
import { LOGIN } from './routes'
import { useAuthStore } from './store'

export const authMiddleware = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized
) => {
  const loginRoute = {
    name: LOGIN,
    query: { redirect_to: to.fullPath !== '/' ? to.fullPath : undefined },
  }

  const store = useAuthStore()
  try {
    const user = await store.fetchMe()
    if (user && to.name === LOGIN) {
      return to.query.redirect_to ? { path: to.query.redirect_to } : { path: from.path }
    }
    if (
      user &&
      user.type === USER_ROLES.PRACTITIONER &&
      [APPOINTMENTS, SURVEYS, HOME].includes(to.name as string)
    ) {
      return { name: PRACTITIONER_SURVEYS }
    }

    if (!to.meta.auth) {
      return true
    }
    if (!user) {
      await store.logout()
      return loginRoute
    }
  } catch (e) {
    if (e instanceof ApiError) {
      if (e?.status >= 500) {
        return '/500'
      }
      if ([401].includes(e?.status)) {
        await store.logout()
        return loginRoute
      }
    }
    throw e
  }
  return true
}
