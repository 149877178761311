import type { RouteRecordRaw } from 'vue-router'

export const SURVEYS = 'surveys'
export const SURVEY_DETAILS = 'survey-details'
export const BSCL_DETAILS = 'bscl-details'
export const PRACTITIONER_SURVEYS = 'questionnaires'
export default [
  {
    path: '/episodes-of-care/:eocId/surveys/:surveyId',
    name: SURVEY_DETAILS,
    component: () => import('./views/TreatmentList.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/episodes-of-care/:eocId/bscl/:bsclId',
    name: BSCL_DETAILS,
    component: () => import('./views/TreatmentList.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/surveys',
    name: SURVEYS,
    component: () => import('./views/TreatmentList.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/questionnaires',
    name: PRACTITIONER_SURVEYS,
    component: () => import('./views/PatientSearch.vue'),
    meta: {
      auth: true,
    },
  },
] as RouteRecordRaw[]
