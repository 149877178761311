import type { RouteRecordRaw } from 'vue-router'

export const INFO = 'info'
export const INFO_DETAILS = 'info-details'

export default [
  {
    path: '/info/articles',
    name: INFO,
    component: () => import('./views/Info.vue'),
    children: [
      {
        name: INFO_DETAILS,
        path: ':id',
        component: () => import('./views/Info.vue'),
      },
    ],
  },
] as RouteRecordRaw[]
