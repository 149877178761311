/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CallExternalAPI } from '../models/CallExternalAPI';
import type { SendTestNotification } from '../models/SendTestNotification';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TestService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns CallExternalAPI
     * @throws ApiError
     */
    public testCallExternalApiCreate({
        requestBody,
    }: {
        requestBody: CallExternalAPI,
    }): CancelablePromise<CallExternalAPI> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/test/call-external-api/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns SendTestNotification
     * @throws ApiError
     */
    public testNotificationCreate({
        requestBody,
    }: {
        requestBody: SendTestNotification,
    }): CancelablePromise<SendTestNotification> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/test/notification/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
