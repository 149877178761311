/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedReadOnlyShortSurveyList } from '../models/PaginatedReadOnlyShortSurveyList';
import type { PatchedPatientUpdateOnlySurvey } from '../models/PatchedPatientUpdateOnlySurvey';
import type { PatientUpdateOnlySurvey } from '../models/PatientUpdateOnlySurvey';
import type { ReadOnlySurvey } from '../models/ReadOnlySurvey';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SurveysService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedReadOnlyShortSurveyList
     * @throws ApiError
     */
    public surveysList({
        episodeOfCare,
        limit,
        offset,
        ordering,
        status,
    }: {
        /**
         * Episode of Care
         */
        episodeOfCare?: Array<number>,
        /**
         * Number of results to return per page.
         */
        limit?: number,
        /**
         * The initial index from which to return the results.
         */
        offset?: number,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * Status
         *
         * * `in_progress` - In Progress
         * * `completed` - Completed
         * * `requested` - Requested
         * * `canceled` - Canceled
         */
        status?: Array<'canceled' | 'completed' | 'in_progress' | 'requested'>,
    }): CancelablePromise<PaginatedReadOnlyShortSurveyList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/surveys/',
            query: {
                'episode_of_care': episodeOfCare,
                'limit': limit,
                'offset': offset,
                'ordering': ordering,
                'status': status,
            },
        });
    }

    /**
     * @returns ReadOnlySurvey
     * @throws ApiError
     */
    public surveysRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this Survey.
         */
        id: number,
    }): CancelablePromise<ReadOnlySurvey> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/surveys/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns PatientUpdateOnlySurvey
     * @throws ApiError
     */
    public surveysUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this Survey.
         */
        id: number,
        requestBody?: PatientUpdateOnlySurvey,
    }): CancelablePromise<PatientUpdateOnlySurvey> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/surveys/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns PatientUpdateOnlySurvey
     * @throws ApiError
     */
    public surveysPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this Survey.
         */
        id: number,
        requestBody?: PatchedPatientUpdateOnlySurvey,
    }): CancelablePromise<PatientUpdateOnlySurvey> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/surveys/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
