/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InfoArticlesDetail } from '../models/InfoArticlesDetail';
import type { PaginatedInfoArticlesListList } from '../models/PaginatedInfoArticlesListList';
import type { PaginatedInfoCategoryList } from '../models/PaginatedInfoCategoryList';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class InfoService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedInfoArticlesListList
     * @throws ApiError
     */
    public infoArticlesList({
        category,
        limit,
        offset,
        ordering,
        search,
    }: {
        category?: number,
        /**
         * Number of results to return per page.
         */
        limit?: number,
        /**
         * The initial index from which to return the results.
         */
        offset?: number,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A search term.
         */
        search?: string,
    }): CancelablePromise<PaginatedInfoArticlesListList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/info/articles/',
            query: {
                'category': category,
                'limit': limit,
                'offset': offset,
                'ordering': ordering,
                'search': search,
            },
        });
    }

    /**
     * @returns InfoArticlesDetail
     * @throws ApiError
     */
    public infoArticlesRetrieve({
        id,
    }: {
        id: number,
    }): CancelablePromise<InfoArticlesDetail> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/info/articles/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns PaginatedInfoCategoryList
     * @throws ApiError
     */
    public infoCategoriesList({
        limit,
        offset,
        ordering,
    }: {
        /**
         * Number of results to return per page.
         */
        limit?: number,
        /**
         * The initial index from which to return the results.
         */
        offset?: number,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
    }): CancelablePromise<PaginatedInfoCategoryList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/info/categories/',
            query: {
                'limit': limit,
                'offset': offset,
                'ordering': ordering,
            },
        });
    }

}
