import type { Status87aEnum } from '@/generated/api'

export const PLATFORMS = {
  WEB: 'web',
  IOS: 'ios',
  ANDROID: 'android',
}

export const TOAST_TIME = 4000

export enum USER_ROLES {
  PATIENT = 'Patient',
  PRACTITIONER = 'Practitioner',
}

export const BREAKPOINTS_PX = {
  sm: '480px',
  md: '768px',
  lg: '1024px',
  xl: '1280px',
  '2xl': '1536px',
  '3xl': '2560px',
}

export const enum BsclTypes {
  Start = 'start',
  End = 'end',
}

export const BSCL_ANSWERS = [
  'bscl.answers.0',
  'bscl.answers.1',
  'bscl.answers.2',
  'bscl.answers.3',
  'bscl.answers.4',
]

export const BSCL_QUESTIONS_COUNT = 53

export const SURVEY_STATUSES: Record<string, Status87aEnum> = {
  REQUESTED: 'requested',
  IN_PROGRESS: 'in_progress',
  COMPLETED: 'completed',
  CANCELED: 'canceled',
}

export const surveyTheme = {
  backgroundImage: '',
  backgroundImageFit: 'cover',
  backgroundImageAttachment: 'scroll',
  backgroundOpacity: 1,
  header: {
    height: 256,
    inheritWidthFrom: 'container',
    textAreaWidth: 512,
    overlapEnabled: false,
    backgroundImageOpacity: 1,
    backgroundImageFit: 'cover',
    logoPositionX: 'right',
    logoPositionY: 'top',
    titlePositionX: 'left',
    titlePositionY: 'bottom',
    descriptionPositionX: 'left',
    descriptionPositionY: 'bottom',
  },
  themeName: 'default',
  isPanelless: false,
  colorPalette: 'light',
  cssVariables: {
    '--sjs-general-backcolor': 'rgba(255, 255, 255, 1)',
    '--sjs-general-backcolor-dark': 'rgba(248, 248, 248, 1)',
    '--sjs-general-backcolor-dim': '#F4F5F7',
    '--sjs-general-backcolor-dim-light': 'rgba(255, 255, 255, 1)',
    '--sjs-general-backcolor-dim-dark': 'rgba(243, 243, 243, 1)',
    '--sjs-general-forecolor': 'rgba(0, 0, 0, 0.91)',
    '--sjs-general-forecolor-light': 'rgba(0, 0, 0, 0.45)',
    '--sjs-general-dim-forecolor': 'rgba(0, 0, 0, 0.91)',
    '--sjs-general-dim-forecolor-light': 'rgba(0, 0, 0, 0.45)',
    '--sjs-primary-backcolor': 'rgba(32, 172, 96, 1)',
    '--sjs-primary-backcolor-light': 'rgba(44, 236, 132, 0.1)',
    '--sjs-primary-backcolor-dark': 'rgba(29, 157, 88, 1)',
    '--sjs-primary-forecolor': 'rgba(255, 255, 255, 1)',
    '--sjs-primary-forecolor-light': 'rgba(255, 255, 255, 0.25)',
    '--sjs-base-unit': '8px',
    '--sjs-corner-radius': '16px',
    '--sjs-secondary-backcolor': 'rgba(255, 152, 20, 1)',
    '--sjs-secondary-backcolor-light': 'rgba(255, 152, 20, 0.1)',
    '--sjs-secondary-backcolor-semi-light': 'rgba(255, 152, 20, 0.25)',
    '--sjs-secondary-forecolor': 'rgba(255, 255, 255, 1)',
    '--sjs-secondary-forecolor-light': 'rgba(255, 255, 255, 0.25)',
    '--sjs-shadow-small': '0px 1px 2px 0px rgba(0, 0, 0, 0.15)',
    '--sjs-shadow-small-reset': '0px 0px 0px 0px rgba(0, 0, 0, 0.15)',
    '--sjs-shadow-medium': '0px 2px 6px 0px rgba(0, 0, 0, 0.1)',
    '--sjs-shadow-large': '0px 8px 16px 0px rgba(0, 0, 0, 0.1)',
    '--sjs-shadow-inner': 'inset 0px 0px 0px 1px rgba(215, 218, 225, 1)',
    '--sjs-shadow-inner-reset': 'inset 0px 0px 0px 0px rgba(215, 218, 225, 1)',
    '--sjs-border-light': 'rgba(0, 0, 0, 0.09)',
    '--sjs-border-default': 'rgba(0, 0, 0, 0.16)',
    '--sjs-border-inside': 'rgba(0, 0, 0, 0.16)',
    '--sjs-special-red': 'rgba(229, 10, 62, 1)',
    '--sjs-special-red-light': 'rgba(229, 10, 62, 0.1)',
    '--sjs-special-red-forecolor': 'rgba(255, 255, 255, 1)',
    '--sjs-special-green': 'rgba(25, 179, 148, 1)',
    '--sjs-special-green-light': 'rgba(25, 179, 148, 0.1)',
    '--sjs-special-green-forecolor': 'rgba(255, 255, 255, 1)',
    '--sjs-special-blue': 'rgba(67, 127, 217, 1)',
    '--sjs-special-blue-light': 'rgba(67, 127, 217, 0.1)',
    '--sjs-special-blue-forecolor': 'rgba(255, 255, 255, 1)',
    '--sjs-special-yellow': 'rgba(255, 152, 20, 1)',
    '--sjs-special-yellow-light': 'rgba(255, 152, 20, 0.1)',
    '--sjs-special-yellow-forecolor': 'rgba(255, 255, 255, 1)',
    '--sjs-article-font-xx-large-textDecoration': 'none',
    '--sjs-article-font-xx-large-fontWeight': '700',
    '--sjs-article-font-xx-large-fontStyle': 'normal',
    '--sjs-article-font-xx-large-fontStretch': 'normal',
    '--sjs-article-font-xx-large-letterSpacing': '0',
    '--sjs-article-font-xx-large-lineHeight': '64px',
    '--sjs-article-font-xx-large-paragraphIndent': '0px',
    '--sjs-article-font-xx-large-textCase': 'none',
    '--sjs-article-font-x-large-textDecoration': 'none',
    '--sjs-article-font-x-large-fontWeight': '700',
    '--sjs-article-font-x-large-fontStyle': 'normal',
    '--sjs-article-font-x-large-fontStretch': 'normal',
    '--sjs-article-font-x-large-letterSpacing': '0',
    '--sjs-article-font-x-large-lineHeight': '56px',
    '--sjs-article-font-x-large-paragraphIndent': '0px',
    '--sjs-article-font-x-large-textCase': 'none',
    '--sjs-article-font-large-textDecoration': 'none',
    '--sjs-article-font-large-fontWeight': '700',
    '--sjs-article-font-large-fontStyle': 'normal',
    '--sjs-article-font-large-fontStretch': 'normal',
    '--sjs-article-font-large-letterSpacing': '0',
    '--sjs-article-font-large-lineHeight': '40px',
    '--sjs-article-font-large-paragraphIndent': '0px',
    '--sjs-article-font-large-textCase': 'none',
    '--sjs-article-font-medium-textDecoration': 'none',
    '--sjs-article-font-medium-fontWeight': '700',
    '--sjs-article-font-medium-fontStyle': 'normal',
    '--sjs-article-font-medium-fontStretch': 'normal',
    '--sjs-article-font-medium-letterSpacing': '0',
    '--sjs-article-font-medium-lineHeight': '32px',
    '--sjs-article-font-medium-paragraphIndent': '0px',
    '--sjs-article-font-medium-textCase': 'none',
    '--sjs-article-font-default-textDecoration': 'none',
    '--sjs-article-font-default-fontWeight': '400',
    '--sjs-article-font-default-fontStyle': 'normal',
    '--sjs-article-font-default-fontStretch': 'normal',
    '--sjs-article-font-default-letterSpacing': '0',
    '--sjs-article-font-default-lineHeight': '28px',
    '--sjs-article-font-default-paragraphIndent': '0px',
    '--sjs-article-font-default-textCase': 'none',
    '--sjs-question-background': 'rgba(255, 255, 255, 1)',
    '--sjs-questionpanel-cornerRadius': '16px',
    '--sjs-font-questiontitle-color': 'rgba(0, 137, 191, 1)',
    '--sjs-font-pagetitle-color': 'rgba(66, 76, 94, 0.91)',
    '--sjs-font-pagetitle-size': '32px',
    '--sjs-font-editorfont-placeholdercolor': 'rgba(123, 134, 157, 1)',
    '--sjs-font-family': 'Open Sans',
    '--sjs-editor-background': 'rgba(255, 255, 255, 1)',
    '--sjs-editorpanel-hovercolor': 'rgba(255, 255, 255, 1)',
    '--sjs-editorpanel-cornerRadius': '12px',
    '--sjs-font-surveytitle-weight': '600',
    '--sjs-font-questiontitle-size': '20px',
    '--sjs-font-questiontitle-weight': '700',
    '--sjs-font-questiondescription-size': '14px',
    '--sjs-font-editorfont-color': 'rgba(68, 76, 92, 0.91)',
    '--sjs-font-editorfont-size': '18px',
  },
}
