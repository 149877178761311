import 'dayjs/locale/en'
import 'dayjs/locale/de'

import dayjs from 'dayjs'
import calendar from 'dayjs/plugin/calendar'
import isToday from 'dayjs/plugin/isToday'
import isTomorrow from 'dayjs/plugin/isTomorrow'

import i18n from '@/i18n'

dayjs.extend(isToday)
dayjs.extend(isTomorrow)
dayjs.locale(i18n.global.locale.value)
dayjs.extend(calendar)

export default dayjs
