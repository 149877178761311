import { defineStore } from 'pinia'
import { ref } from 'vue'

import { useApiClient } from '@/api'
import type { CancelablePromise, PaginatedInfoArticlesListList } from '@/generated/api'

export const useInfoStore = defineStore('info', () => {
  const apiClient = useApiClient()
  const hasInfoArticles = ref(false)

  function getInfoCategories() {
    return apiClient.info.infoCategoriesList({ limit: 1000 })
  }

  function getInfoCategoryArticleList(params: {
    category: number
    limit?: number
    offset?: number
    ordering?: string
    search?: string
  }): CancelablePromise<PaginatedInfoArticlesListList> {
    return apiClient.info.infoArticlesList({ limit: 20, ...params })
  }

  function getInfoArticleList(params: {
    limit?: number
    offset?: number
    ordering?: string
    search?: string
    category?: number
  }): CancelablePromise<PaginatedInfoArticlesListList> {
    return apiClient.info.infoArticlesList({ limit: 20, ...params })
  }

  async function loadNextInfoArticles(url: string): Promise<PaginatedInfoArticlesListList | null> {
    const urlObj = new URL(url)
    const newURLWithoutHost = urlObj.href.replace(urlObj.origin, '')
    return apiClient.request.request({ method: 'GET', url: newURLWithoutHost })
  }

  function getInfoArticle(params: { id: number }) {
    return apiClient.info.infoArticlesRetrieve(params)
  }

  return {
    getInfoCategories,
    getInfoCategoryArticleList,
    getInfoArticle,
    getInfoArticleList,
    loadNextInfoArticles,
    hasInfoArticles,
  }
})
