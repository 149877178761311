/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InvitationRetrieveToken } from '../models/InvitationRetrieveToken';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class InvitationService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns InvitationRetrieveToken
     * @throws ApiError
     */
    public invitationRetrieve({
        token,
    }: {
        token: string,
    }): CancelablePromise<InvitationRetrieveToken> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/invitation/{token}/',
            path: {
                'token': token,
            },
        });
    }

}
