import type { RouteRecordRaw } from 'vue-router'

export const APPOINTMENTS = 'appointments'

export default [
  {
    path: '/appointments',
    name: APPOINTMENTS,
    component: () => import('./views/Appointments.vue'),
    meta: {
      auth: true,
    },
  },
] as RouteRecordRaw[]
