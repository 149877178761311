import { computed } from 'vue'

import { USER_ROLES } from '@/constants/constants'
import { useAuthStore } from '@/modules/auth/store'

export function useUserRoles() {
  const store = useAuthStore()

  const isPatient = computed(() => store.user?.type === USER_ROLES.PATIENT)
  const isPractitioner = computed(() => store.user?.type === USER_ROLES.PRACTITIONER)

  return {
    isPatient,
    isPractitioner,
  }
}
