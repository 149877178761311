/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PatchedUpdatePassword } from '../models/PatchedUpdatePassword';
import type { PatchedUser } from '../models/PatchedUser';
import type { ProfileReadOnly } from '../models/ProfileReadOnly';
import type { UpdatePassword } from '../models/UpdatePassword';
import type { User } from '../models/User';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class MeService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns ProfileReadOnly
     * @throws ApiError
     */
    public meRetrieve(): CancelablePromise<ProfileReadOnly> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/me/',
        });
    }

    /**
     * @returns User
     * @throws ApiError
     */
    public meUpdateUpdate({
        requestBody,
    }: {
        requestBody: User,
    }): CancelablePromise<User> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/me/update/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns User
     * @throws ApiError
     */
    public meUpdatePartialUpdate({
        requestBody,
    }: {
        requestBody?: PatchedUser,
    }): CancelablePromise<User> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/me/update/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * An endpoint for changing password.
     * @returns UpdatePassword
     * @throws ApiError
     */
    public meUpdatePasswordUpdate({
        requestBody,
    }: {
        requestBody: UpdatePassword,
    }): CancelablePromise<UpdatePassword> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/me/update-password/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * An endpoint for changing password.
     * @returns UpdatePassword
     * @throws ApiError
     */
    public meUpdatePasswordPartialUpdate({
        requestBody,
    }: {
        requestBody?: PatchedUpdatePassword,
    }): CancelablePromise<UpdatePassword> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/me/update-password/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
