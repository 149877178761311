<template>
  <template v-if="authStore.user || route.name !== INFO_DETAILS">
    <AppSidebar v-model:open="showSidebar" />
    <div class="layout-container safe-top">
      <div class="glow"></div>
      <header class="header" :class="{ home }">
        <Badge :label="totalCounter">
          <Button
            size="large"
            type="secondary"
            icon="menu-1"
            icon-only
            @click="showSidebar = true"
            data-qa="open-menu-button"
          />
        </Badge>
        <h1 v-if="!isTablet && !home" class="title text-xl m-0 ml-5" data-qa="page-title">
          {{ title }}
        </h1>
      </header>
      <div
        class="content mobile-scroll-container"
        :class="[isWide ? 'p-0' : 'md:max-w-sm px-5 md:px-0']"
      >
        <div v-if="!isWide && isTablet" class="title w-full text-4xl pt-10 pb-5">
          {{ title }}
        </div>
        <slot />
      </div></div
  ></template>
  <slot v-else />
</template>
<script setup lang="ts">
import { App } from '@capacitor/app'
import { Capacitor } from '@capacitor/core'
import { PushNotifications } from '@capacitor/push-notifications'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import Badge from '@/components/common/badge/Badge.vue'
import Button from '@/components/common/buttons/Button.vue'
import { useScreens } from '@/composables/useScreens'
import { APPOINTMENTS } from '@/modules/appointments/routes'
import { useAuthStore } from '@/modules/auth/store'
import { useCounterStore } from '@/modules/counter/store'
import { HISTORY } from '@/modules/history/routes'
import { HOME } from '@/modules/home/routes'
import { INFO_DETAILS } from '@/modules/info/routes'

import AppSidebar from '../components/page/AppSidebar.vue'

const route = useRoute()
const showSidebar = ref(false)
const { isTablet } = useScreens()
const { t } = useI18n()
const store = useCounterStore()
const authStore = useAuthStore()

const title = computed(() => t(`page-titles.${String(route.name)}`))

const totalCounter = computed(
  () => (store.counter && Object.values(store.counter).reduce((val, acc) => acc + val, 0)) || 0
)

const isWide = computed(() => [HOME, APPOINTMENTS, HISTORY].includes(route.name as string))
const home = computed(() => route.name === HOME)
onMounted(async () => {
  if (!Capacitor.isNativePlatform()) return
  App.addListener('appStateChange', ({ isActive }) => {
    if (isActive) {
      return
    }
    showSidebar.value = false
  })

  PushNotifications.requestPermissions().then((result) => {
    if (result.receive === 'granted') {
      PushNotifications.register()
    } else {
      localStorage.setItem('fcmToken', '')
    }
  })
})
</script>

<style scoped lang="postcss">
.layout-container {
  @apply w-full h-full bg-base-gradient flex flex-col relative md:items-center;

  .glow {
    background-image: url('@/assets/background-glow.png');
    background-size: cover;
    @apply absolute z-0 top-0 right-0 w-[390px] h-[401px] md:w-[602px] md:h-[555px] pointer-events-none;
  }

  .header {
    @apply p-5 flex items-center border-b border-gray-900-10 md:border-none w-full;
    &.home {
      @apply border-b-0;
    }
  }

  .title {
    @apply text-accent-500 font-bold capitalize;
  }

  .content {
    @apply flex-grow flex flex-col items-center z-[1] w-full overflow-y-auto;
  }
}
</style>
