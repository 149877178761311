import { Capacitor } from '@capacitor/core'

import { ApiClient } from '@/generated/api/ApiClient'
import i18n from '@/i18n'

import { useAuthStore } from './modules/auth/store'

let client: ApiClient | null = null
export const useApiClient = () => {
  if (!client) {
    const authStore = useAuthStore()
    client = new ApiClient({
      BASE: Capacitor.isNativePlatform() ? import.meta.env.VITE_BASE_URL : '',
      HEADERS: async (opts): Promise<Record<string, string>> => {
        if (opts.method === 'POST' && opts.url.indexOf('login/refresh') > -1) {
          return {}
        }
        const token = await authStore.getAccessToken()
        const headers = { 'Accept-Language': i18n.global.locale.value }
        return token ? { ...headers, Authorization: `Bearer ${token}` } : headers
      },
    })
  }
  return client
}
