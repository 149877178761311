import { useMediaQuery } from '@vueuse/core'

import { BREAKPOINTS_PX } from '@/constants/constants'

export function useScreens() {
  const isTablet = useMediaQuery(`(min-width: ${BREAKPOINTS_PX.md})`)
  const isMobile = useMediaQuery(`(max-width: ${BREAKPOINTS_PX.md})`)
  return {
    isTablet,
    isMobile,
  }
}
