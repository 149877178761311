/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BSCLQuestions } from '../models/BSCLQuestions';
import type { PaginatedReadOnlyShortBSCLSurveyList } from '../models/PaginatedReadOnlyShortBSCLSurveyList';
import type { PatchedUpdateOnlyBSCLSurvey } from '../models/PatchedUpdateOnlyBSCLSurvey';
import type { ReadOnlyBSCLSurvey } from '../models/ReadOnlyBSCLSurvey';
import type { UpdateOnlyBSCLSurvey } from '../models/UpdateOnlyBSCLSurvey';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class BsclService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PaginatedReadOnlyShortBSCLSurveyList
     * @throws ApiError
     */
    public bsclList({
        episodeOfCare,
        limit,
        offset,
        ordering,
        status,
    }: {
        /**
         * Episode of Care
         */
        episodeOfCare?: Array<number>,
        /**
         * Number of results to return per page.
         */
        limit?: number,
        /**
         * The initial index from which to return the results.
         */
        offset?: number,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * Status
         *
         * * `in_progress` - In Progress
         * * `completed` - Completed
         * * `requested` - Requested
         * * `canceled` - Canceled
         */
        status?: Array<'canceled' | 'completed' | 'in_progress' | 'requested'>,
    }): CancelablePromise<PaginatedReadOnlyShortBSCLSurveyList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/bscl/',
            query: {
                'episode_of_care': episodeOfCare,
                'limit': limit,
                'offset': offset,
                'ordering': ordering,
                'status': status,
            },
        });
    }

    /**
     * @returns ReadOnlyBSCLSurvey
     * @throws ApiError
     */
    public bsclRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this BSCL Questionnaire.
         */
        id: number,
    }): CancelablePromise<ReadOnlyBSCLSurvey> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/bscl/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns UpdateOnlyBSCLSurvey
     * @throws ApiError
     */
    public bsclUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this BSCL Questionnaire.
         */
        id: number,
        requestBody?: UpdateOnlyBSCLSurvey,
    }): CancelablePromise<UpdateOnlyBSCLSurvey> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/bscl/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns UpdateOnlyBSCLSurvey
     * @throws ApiError
     */
    public bsclPartialUpdate({
        id,
        requestBody,
    }: {
        /**
         * A unique integer value identifying this BSCL Questionnaire.
         */
        id: number,
        requestBody?: PatchedUpdateOnlyBSCLSurvey,
    }): CancelablePromise<UpdateOnlyBSCLSurvey> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/bscl/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns BSCLQuestions
     * @throws ApiError
     */
    public bsclQuestionsList(): CancelablePromise<Array<BSCLQuestions>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/bscl/questions/',
        });
    }

}
