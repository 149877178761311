<template>
  <div class="overlay">
    <slot />
  </div>
</template>

<style scoped lang="postcss">
.overlay {
  @apply absolute inset-0 z-[970] flex flex-col items-center justify-center bg-black opacity-20;
}
</style>
