import type { RouteRecordRaw } from 'vue-router'

export const HOME = 'home'

export default [
  {
    path: '/home',
    name: HOME,
    component: () => import('./views/Home.vue'),
    meta: {
      auth: true,
    },
  },
] as RouteRecordRaw[]
