/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FCMToken } from '../models/FCMToken';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class FcmtokenService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns FCMToken
     * @throws ApiError
     */
    public fcmtokenCreate({
        requestBody,
    }: {
        requestBody: FCMToken,
    }): CancelablePromise<FCMToken> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/fcmtoken/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public fcmtokenDestroy({
        token,
    }: {
        /**
         * A unique value identifying this FCM Token.
         */
        token: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/fcmtoken/{token}/',
            path: {
                'token': token,
            },
        });
    }

}
